import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		cookbookItems: [{
			title: 'Bolognese',
			picture: 'https://veganroulette.com/assets/recipe/bolognese.jpg',
			shortDescription: 'Een rijke, machtige bolognesesaus: perfect voor die koude winterdagen. Kost je een middag, maar is het 100% waard.',
			longDescription: `1. Bolognese met extra tomaat<br/>
				2. Een rijke, machtige bolognesesaus met extra tomaat.<br/>
				3. Plantaardige boter, olijfolie, 400 gram vegan gehakt,<br/>peper & zout, selderij (4 à 5 stengels), 250 gram ui,<br/>250 gram wortel, 
				verse knoflook, 250 ml droge witte wijn,<br/>380 milliliter paddenstoelenfond, 700 milliliter passata pomodoro, 140 gram tomatenpuree.<br/>
				4. Bereidingstijd is minstens 2.5 uur (liefst 4.5)<br/>
				5. Voeg twee eetlepels boter en twee eetlepels olijfolie toe aan een grote koekenpan. Zodra de boter en olie op temperatuur zijn, de fijngesneden ui en knoflook (aantal teentjes naar smaak) toevoegen.<br/><br/>
				Begint de ui te glanzen, dan kun je het vegan gehakt erbij gooien. Rul het gehakt en voeg peper & zout naar smaak toe. 
				Voeg vier à vijf in blokjes gesneden selderijstengels toe, samen met 250 gram fijngesneden wortel.
				Na 5-10 minuten kun je 250 milliliter droge witte wijn toevoegen. Zodra de wijn is ingekookt, is het tijd om de paddenstoelenfond en de tomatenpuree erbij te schenken.
				Vanaf dit moment kan je de bolognese minstens twee, maar het liefst vier uur laten pruttelen. De fles passata pomodoro kan je gedurende deze tijd stukje bij beetje toevoegen.
				Kijk uit dat de saus niet aanbakt. Serveren met verse pasta of warm stokbrood.<br/><br/>Eet smakelijk!`,
			time: 'Minstens 2,5 uur, liefst 4 uur'
		}]
	},
	getters: {
		cookbookItems (state) {
			return state.cookbookItems
		}
	},
	mutations: {
		cookbookItems (state, data) {
			state.cookbookItems = data
		}
	},
	actions: {
		cookbookItems (context) {
			return new Promise((resolve, reject) => {
				axios.get('https://bwebdesign.nl/veganroulette/api/read.php', {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				}).then(response => {
					context.commit('cookbookItems', response.data.body)
					resolve()
				})
			})
		}
	},
	modules: {}
})
