<template>
  <div class="it">
    <h1>{{ timeOfDayGreetingText }}</h1><br/>

      <h2>Welcome to Vegan Roulette!</h2><br/><br/>
      <p>We've gathered some of the best vegan recipes for you to discover in our Cookbook.<br/></p>
      <p>Or, if you're feeling adventurous, you can spin our wheel below and let fate decide your recipe via our Vegan Roulette.<br/><br/></p>
      <p>Make your choice, what'll it be?<br/></p>

    <roulette />

  </div>
</template>

<script>
import Roulette from '../components/Roulette.vue'
export default {
	components: { Roulette },
	name: 'ExplainHome',
	props: {
		msg: String
	},

	data: function () {
		return {
			timeOfDayGreetingText: ''
		}
	},

	methods: {
		timeOfDayGreeting () {
			var d = new Date()
			var time = d.getHours()

			if (time < 12) {
				return 'Good morning!'
			}
			if (time > 12 && time < 18) {
				return 'Good afternoon!'
			}
			if (time >= 18 && time < 24) {
				return 'Good evening!'
			}
			if (time === 12) {
				return 'Hi, enjoy your lunch!'
			}
			if (time >= 24) {
				return 'Good night!'
			}
		}
	},

	mounted () {
		this.timeOfDayGreetingText = this.timeOfDayGreeting()
	}
}
</script>

<style scoped>
.it{
  line-height: 20px;
}
h1 {
  margin: 40px 0 0;
}
</style>
