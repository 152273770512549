<template>
<div>
<div style="background-color:#fcdde8">
  <div>
    <v-row style="display:inline">
    <div class="centerContent">
    <intro-text post-title="Vegan Roulette" v-bind:showText="true"/>
    </div>
    </v-row>
  </div>
</div>
  <v-container>
    <v-row style="display:inline">
    <div id="centerContent">
    <explain-home/>
    </div>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import IntroText from '../components/IntroText.vue'
import ExplainHome from '../components/Home.vue'

export default {
	name: 'Home',
	components: {
		IntroText,
		ExplainHome
	},

	data: function () {
		return {
		}
	},

	methods: {
	},

	mounted () {
	}

}
</script>
<style scoped>
  .centerContent{
      display: flex;
  align-items: center;
  justify-content: center;
  }
</style>
