import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
