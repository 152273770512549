<template>
  <v-app id="app">
    <router-view/>
  </v-app>
</template>

<script>
export default {
	mounted () {
		this.$store.dispatch('cookbookItems')
	}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app h1, #app h2{
  font-family: 'Hachi Maru Pop', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#app p{
  font-family: 'Raleway', sans-serif !important;
}

body {
	margin: 0;
  padding: 0px;
  background-color: #e5f3ff;
}

#app{
  background-color: #e5f3ff !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
