<template>
  <div>
    <h1 :class="{ opac: active, notVisible: pageNotLoaded }">
      {{ postTitle }}
    </h1>
  </div>
</template>

<script>
export default {
	name: 'IntroText',
	props: { postTitle: String, showText: Boolean },

	data: function () {
		return {
			active: true,
			pageNotLoaded: true
		}
	},

	methods: {
		showContent: function () {
			setTimeout(
				function () {
					this.pageNotLoaded = false
				}.bind(this),
				100
			)

			setTimeout(
				function () {
					this.active = false
				}.bind(this),
				2000
			)
		}
	},

	watch: {
		showText: function (val) {
			console.log('active', val)
			if (val === true) {
				this.showContent()
			}

			if (val === false) {
				this.pageNotLoaded = true
				this.active = true
			}
		}
	},

	mounted () {
		if (this.showText === true) {
			this.showContent()
		}
	}
}
</script>

<style lang="scss" scoped>
*,
*:after,
*:before {
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: calc(1em + 7vw) !important;
  }
}

@media only screen and (max-width: 580px) {
  h1 {
    font-size: calc(1em + 10vw) !important;
  }
}

@media only screen and (max-width: 715px) {
  h1 {
    text-shadow: 6px 5px 0px #eb452b, 9px 10px 0px #efa032, 12px 15px 0px #46b59b !important;
  }
}

.notVisible {
  opacity: 0;
}

h1.opac {
  color: rgb(252, 221, 232) !important;
  text-shadow: -0.0075em 0.0075em 0 mix(#fff, #e91e63, 94%),
    0.005em 0.005em 0 mix(#fff, #e91e63, 60%),
    0.01em 0.01em 0 mix(#fff, #e91e63, 62%),
    0.015em 0.015em mix(#fff, #e91e63, 64%),
    0.02em 0.02em 0 mix(#fff, #e91e63, 66%),
    0.025em 0.025em 0 mix(#fff, #e91e63, 68%),
    0.03em 0.03em 0 mix(#fff, #e91e63, 70%),
    0.035em 0.035em 0 mix(#fff, #e91e63, 72%);
}

h1 {
  opacity: 1;
  transition: opacity 1s, text-shadow 1s;

  text-align: center !important;
  font-family: "Hachi Maru Pop", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: calc(1em + 5vw);
  font-weight: 900;
  line-height: calc(1em + 0.2vw);
  color: #fcedd8;
  padding-bottom: 50px;
  margin: 0px;
  text-shadow: 5px 5px 0px #eb452b, 10px 10px 0px #efa032, 15px 15px 0px #46b59b;
  //   20px 20px 0px #017e7f,
  //   25px 25px 0px #052939,
  //   30px 30px 0px #c11a2b,
  //   35px 35px 0px #c11a2b,
  //   40px 40px 0px #c11a2b,
  //   45px 45px 0px #c11a2b;
}
</style>
