<template>
  <div style="display:inline, text-align:center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      style="background-color: white"
    >
      <v-card style="background-color: #e5f3ff">
        <v-expand-transition>
          <div style="background-color: #fcdde8">
            <div>
              <v-row style="display: inline">
                <div class="centerContent">
                  <intro-text
                    :post-title="dialogHeaderTitle"
                    v-bind:showText="true"
                  />
                </div>
              </v-row>
            </div>
          </div>
        </v-expand-transition>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="slot-machine">
                <div
                  class="slot"
                  v-for="slot in slots"
                  :key="slot.title"
                  ref="slots"
                >
                  <div class="slot__window">
                    <div class="slot__wrap">
                      <div
                        class="slot__item"
                        v-for="opt in slot.items"
                        :key="opt.title"
                      >
                        <v-card style="background-color: #e5f3ff">
                          <v-img
                            :src="opt.picture"
                            max-height="226"
                            class="grey darken-4"
                          ></v-img>
                        </v-card>
                      </div>
                      <div class="slot__item slot__item--copy">
                        <v-card style="background-color: #e5f3ff">
                          <v-img
                            :src="slot.items[0].picture"
                            max-height="226"
                            class="grey darken-4"
                          ></v-img>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" v-if="animationDone">
              <h2>
                Yummy! The wheel has chosen <b style="color:#DB024E">{{ chosenRecipe.title }}</b>
              </h2>
              <br />
              <p>
                <v-icon large color="green darken-2" style="padding-right:5px">
                  mdi-clock-outline
                </v-icon>
                <b>{{ chosenRecipe.time }}</b
                ><br /><br />
              </p>
              <p>{{ chosenRecipe.shortDescription }}<br /></p>
            </v-col>
            <v-col cols="12" v-if="animationDone">
              <v-btn
                class="ma-4 blue white--text customFont pulse"
                style="text-transform: none"
                elevation="5"
                x-large
                :to="'/cookbook/'+chosenRecipe.title"
              >
                <v-icon left dark> mdi-baguette </v-icon>
                Open recipe </v-btn
              ><br />
              <v-btn
                class="ma-4 gray black--text customFont white"
                style="text-transform: none"
                @click="start"
              >
                <v-icon left dark> mdi-balloon </v-icon>
                Spin the wheel again! </v-btn
              ><br />
              <v-btn
              class="gray black--text customFont white"
                style="text-transform: none"
                to="/cookbook"
              >
                <v-icon left dark style="background-color: #ffffff"> mdi-book-open-page-variant </v-icon>
                Or browse through the Cookbook
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="animationDone === false" style="text-align:center">
            <!-- <loadingIcon class="icon" /> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-btn
      class="ma-4 blue white--text customFont pulse"
      style="text-transform: none"
      elevation="5"
      x-large
      @click="openDialogAndStart"
    >
      <v-icon left dark> mdi-balloon </v-icon>
      Spin the wheel! </v-btn
    ><br />
    <v-btn
      class="gray black--text customFont white"
      style="text-transform: none"
      x-large
      to="/cookbook"
    >
      <v-icon left dark> mdi-book-open-page-variant </v-icon>
      Browse through the Cookbook
    </v-btn>
  </div>
</template>

<script>
import IntroText from '../components/IntroText.vue'

const next =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  function (cb) { window.setTimeout(cb, 1000 / 60) }

export default {
	name: 'Roulette',

	components: {
		IntroText
	},

	computed: {
		cookbookItems () {
			return this.$store.getters.cookbookItems
		}
	},

	data: function () {
		return {
			dialog: false,
			recipe: '',
			globalNotFinished: true,
			chosenRecipe: [],
			animationDone: false,

			dialogHeaderTitle: 'Spinning...',

			slots: [
				{
					items: this.$store.getters.cookbookItems
				}
			],
			opts: null,
			startedAt: null
		}
	},

	methods: {
		resetSpinner () {
			this.dialogHeaderTitle = 'Spinning...'
			this.animationDone = false
			this.globalNotFinished = true
		},

		openDialogAndStart () {
			this.dialog = true
			setTimeout(
				function () {
					this.start()
				}.bind(this),
				100
			)
		},
		start: function () {
			this.resetSpinner()
			if (this.opts) {
				return
			}

			this.opts = this.slots.map((data, i) => {
				const slot = this.$refs.slots[i]
				const choice = Math.floor(Math.random() * data.items.length)
				const result = data.items[choice]
				const heightWin = 240
				this.chosenRecipe = result
				// console.log('choice', i, result)

				const opts = {
					el: slot.querySelector('.slot__wrap'),
					finalPos: choice * heightWin,
					startOffset: 2000 + Math.random() * 500 + i * 500,
					height: data.items.length * heightWin,
					duration: 2000 + i * 700, // milliseconds
					isFinished: false
				}

				return opts
			})

			next(this.animate)
		},

		animate: function (timestamp) {
			if (this.startedAt == null) {
				this.startedAt = timestamp
			}

			const timeDiff = timestamp - this.startedAt

			this.opts.forEach((opt) => {
				if (opt.isFinished) {
					return
				}

				const timeRemaining = Math.max(opt.duration - timeDiff, 0)
				const power = 3
				const offset =
          (Math.pow(timeRemaining, power) / Math.pow(opt.duration, power)) *
          opt.startOffset

				// negative, such that slots move from top to bottom
				const pos = -1 * Math.floor((offset + opt.finalPos) % opt.height)

				opt.el.style.transform = 'translateY(' + pos + 'px)'

				if (timeDiff > opt.duration) {
					// console.log('finished', opt, pos, opt.finalPost)
					opt.isFinished = true

					this.globalNotFinished = false
					this.animationDone = true
					this.dialogHeaderTitle = this.chosenRecipe.title
				}
			})

			if (this.opts.every((o) => o.isFinished)) {
				this.opts = null
				this.startedAt = null
				// console.log('finished')

				this.globalNotFinished = false
				this.animationDone = true
				this.dialogHeaderTitle = this.chosenRecipe.title
			} else {
				next(this.animate)
			}
		}
	}
}
</script>

<style scoped>
.customFont {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.slot__window {
  /*background-color: green;*/
  width: 100%;
  height: 230px;
  overflow: hidden;
}

.slot__item {
  height: 240px;
  width: 100%;

  text-align: center;
  /*background-color: blue;*/
  color: white;

  line-height: 250px;
}

.hidden {
  visibility: hidden;
}
</style>
